/**
 * @desc 运行环境获取
 * @return {String}
*/
const apiUrl = isLocal() ? 'https://api.platform-dev.dtedu.com' : getHost()

function isLocal() {
  const hostname = window.location.hostname
  return !(
    hostname.includes('dev') ||
    hostname.includes('test') ||
    hostname.includes('pre') ||
    hostname.includes('dt-edu')
  )
}

function getEnv(){
  const hostname = window.location.hostname
  if(hostname.includes('test')){
    return 'test'
  }else if(hostname.includes('pre')){
    return 'pre'
  }else if(hostname.includes('dt-edu')){
    return 'prd'
  }else{
    return 'dev'
  }
}

function GotoPlatform() {
  const hostname = window.location.hostname
  if(hostname.includes('test')){
    return 'https://platform-test.dtedu.com/'
  }else if(hostname.includes('pre')){
    return 'https://platform-pre.dtedu.com/'
  }else if(hostname.includes('dt-edu')){
    return 'https://www.dt-pf.com/'
  }else{
    return 'https://platform-dev.dtedu.com/'
  }
}

function getHost() {
  const hostname = window.location.hostname
  if(hostname.includes('test')){
    return 'https://api.platform-test.dtedu.com'
  }else if(hostname.includes('pre')){
    return 'https://api.platform-pre.dtedu.com'
  }else if(hostname.includes('dt-edu')){
    return 'https://api.dt-pf.com'
  }else{
    return 'https://api.platform-dev.dtedu.com'
  }
}

export { isLocal, apiUrl, getEnv, GotoPlatform }
